import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, Spin, Steps, Tabs } from 'antd';
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Document, Page, Text, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import { motion, AnimatePresence } from "framer-motion";
import { useAuth } from '../Auth';
import logo2 from '../ulrc_logo.png';
import Visa from '../assets/visa1.png';
import Mastercard from '../assets/mastercard2.png';
import Paypal from '../assets/paypal.png';
import mtn from '../assets/mtn.png';
import airtel from '../assets/airtel1.png';
import Footer from './Footer';
import { AuthHeader2 } from './UnAuthHeader';
import axios from 'axios';
import PaymentModal from './PaymentModal';
import { MdEmail, MdLocationOn, MdPerson, MdPhone } from 'react-icons/md';
import { toast } from 'react-toastify';
import { API_PAY_URL, API_URL, BASE_URL, DEV_PAY_URL, PAYMENT_SERVER } from '../envs';
import { Select } from 'antd';
import { subscriptions } from './Pricing';
import qs from 'qs';
import { checkingPRN } from '../utils/methods';

export function currentDateFormat() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

const choices = [
    Visa,
    Mastercard,
    mtn,
    airtel
]

const cardChoices = [
    Visa,
    Mastercard
]

const mobileChoices = [
    {
        image: mtn,
        code: "mtn_momo_ug"
    },
    {
        image: airtel,
        code: "airtel_money_ug"
    }
]

const cardChoices2 = [
    {
        image: Visa,
        code: "card_ug"
    },
    {
        image: Mastercard,
        code: "card_ug"
    }
]

const paymentMethods = {
    mm: "MOBILE_MONEY",
    card: "CARD",
}

const initialPayInfo = {
    TaxPayerName: "",
    ContactNo: "",
    TinNo: "",
    code: "",
    method: ""
}

export const PRN_CODE = {
    T: {
        short: 'RECEIVED AND CREDITED',
        desc: 'Payment is reconciled with the bank'
    },
    R: {
        short: 'RECEIVED BUT NOT CREDITED',
        desc: 'The cheque has not yet matured'
    },
    D: {
        short: 'RECEIVED BUT DISHONOURED',
        desc: 'The cheque is dishonoured'
    },
    A: {
        short: 'AVAILABLE',
        desc: 'This is a valid PRN that has not been paid against.'
    },
    C: {
        short: 'CANCELLED',
        desc: 'The PRN has been cancelled'
    },
    X: {
        short: 'EXPIRED',
        desc: 'The PRN has expired'
    }
}

const subscriptionEnd = (startDate, duration) => {
    const multiplier = duration === "Annually" ? 365 : duration === "Monthly" ? 30 : 1
    const newDate = new Date(startDate)
    newDate.setDate(newDate.getDate() + multiplier)
    return newDate
}


const PrnStatus = ({ isModalOpen, handleCancel, loading, prnStatus }) => {
    return (
        <Modal footer={null} title="PRN Status" open={isModalOpen} onCancel={handleCancel}>
            {loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />}
            {!loading && prnStatus && (
                <div className='mt-6'>
                    {/* prnStatus is an object which contains StatusCode, a prn and an amount. Display the below  */}
                    <div className='flex justify-between'>
                        <span>PRN</span>
                        <span className='font-bold'>{prnStatus.PRN}</span>
                    </div>
                    <div className='flex justify-between'>
                        <span>Amount</span>
                        <span className='font-bold'>{prnStatus.AmountPaid}</span>
                    </div>
                    <div className='flex justify-between'>
                        <span>Status</span>
                        <span className='font-bold'>{PRN_CODE[prnStatus.StatusCode]?.short}</span>
                    </div>
                    <div className='flex justify-between'>
                        <span>Description</span>
                        <span className='font-bold'>{PRN_CODE[prnStatus.StatusCode]?.desc}</span>
                    </div>
                </div>)}
        </Modal>
    )
}

const PRN_ERROR_MESSAGES = {
    external: "This PRN was not generated from this system and cannot be used to access ULRC services",
    notYours: "This PRN was generated by another account",
    notYours1: "This PRN was not generated by your account"
}

const PrnStatus2 = ({ isModalOpen, handleCancel, loading, prnStatus, setPrn, testPrn }) => {
    const { userData, _setSubscription } = useAuth()
    const [showBtn, setShowBtn] = useState(false)
    const [prnSubData, setPrnSubData] = useState(null)
    const [prnMessage, setPrnMessage] = useState(null)

    useEffect(() => {
        if (!testPrn || !isModalOpen) {
            return;
        }
        (async () => {
            try {
                const query = qs.stringify({
                    filters: {
                        prn: {
                            '$eq': testPrn
                        }
                    }
                }, {
                    encodeValuesOnly: true,
                });
                const response = await axios.get(`${API_URL}/prns?${query}`);
                const data = response.data?.data
                console.log("DATA:", data)
                if (data.length === 0) {
                    setPrnMessage(PRN_ERROR_MESSAGES.external)
                    return;
                } else {
                    const prnData = response.data?.data[0]?.attributes;
                    if (prnData?.emailID !== userData?.user?.email && PRN_CODE[prnStatus.StatusCode]?.short === PRN_CODE.A.short) {
                        setPrnMessage(PRN_ERROR_MESSAGES.notYours)
                        return;
                    }
                    if (prnData?.emailID !== userData?.user?.email) {
                        setPrnMessage(PRN_ERROR_MESSAGES.notYours)
                        return;
                    }
                    const prnSub = {
                        package: prnData.package,
                        duration: prnData.packageDuration,
                        noOfUsers: prnData.numberOfUsers
                    }
                    setPrnSubData(prnSub)
                }
            } catch (error) {
                console.error(error);
            }
        })()
    }, [testPrn, isModalOpen, prnStatus])

    useEffect(() => {
        if (prnStatus?.StatusCode === 'A' && prnStatus?.TaxPayerEmail === userData?.user?.email) {
            if (!!prnSubData) {
                setShowBtn(true)
            } else {
                setShowBtn(false)
            }
        } else {
            setShowBtn(false)
        }
        if (loading) {
            setShowBtn(false)
        }
    }, [prnStatus, loading])

    function findSubscription(subscriptions, subscriptionData) {
        return subscriptions.flat().find(sub => {
            return (
                sub.package === subscriptionData.package &&
                sub.duration === subscriptionData.duration &&
                sub.noOfUsers === subscriptionData.noOfUsers
            );
        });
    }

    const handlePrnSubscriptionData = async () => {
        try {
            // const query = qs.stringify({
            //     filters: {
            //         prn: {
            //             '$eq': prnStatus.PRN
            //         }
            //     }
            // }, {
            //     encodeValuesOnly: true,
            // });
            // const response = await axios.get(`${API_URL}/prns?${query}`);
            // const prnData = response.data?.data[0]?.attributes;
            // const prnSub = {
            //     package: prnData.package,
            //     duration: prnData.packageDuration,
            //     noOfUsers: prnData.numberOfUsers
            // }
            const mySubscription = findSubscription(subscriptions, prnSubData)
            _setSubscription(mySubscription)
            setPrn(testPrn)
        } catch (error) {

        }
    }

    const handleClose = () => {
        setPrnMessage(null)
        handleCancel()
    }

    return (
        <Modal footer={null} title="PRN Status" open={isModalOpen} onCancel={handleClose}>
            {loading && <>
                <span>Loading PRN Status </span><Spin indicator={<LoadingOutlined style={{ fontSize: 16, marginLeft: 5 }} spin />} />
            </>}
            {!loading && prnStatus && (
                <div className='mt-6'>
                    {/* prnStatus is an object which contains StatusCode, a prn and an amount. Display the below  */}
                    <div className='flex justify-between'>
                        <span>PRN:</span>
                        <span className='font-bold'>{prnStatus.PRN}</span>
                    </div>
                    <div className='flex justify-between'>
                        <span>Amount:</span>
                        <span className='font-bold'>{prnStatus.AmountPaid}</span>
                    </div>
                    <div className='flex justify-between'>
                        <span>Status:</span>
                        <span className='font-bold'>{PRN_CODE[prnStatus.StatusCode]?.short}</span>
                    </div>
                    <div className='flex justify-between'>
                        <span>Description:</span>
                        <span className='font-bold'>{PRN_CODE[prnStatus.StatusCode]?.desc}</span>
                    </div>
                </div>)}

            {prnMessage && <div className='text-red-500 mt-5 text-center font-mono'>{prnMessage}</div>}

            {showBtn && <div className='flex justify-end'>
                <Button className='mt-7' onClick={handlePrnSubscriptionData}>Proceed to Pay</Button>
            </div>}

        </Modal>
    )
}

const PaymentSlip = ({ packageName, duration, amount, prn, prnDuration }) => (
    <Document>
        <Page style={styles.page}>
            <Text style={styles.title}>Payment Slip</Text>
            <Text style={styles.label}>Package:</Text>
            <Text style={styles.value}>{packageName}</Text>
            <Text style={styles.label}>Duration:</Text>
            <Text style={styles.value}>{duration}</Text>
            <Text style={styles.label}>Amount:</Text>
            <Text style={styles.value}>{amount}</Text>
            <Text style={styles.label}>PRN:</Text>
            <Text style={styles.value}>{prn}</Text>
            <Text style={styles.label}>PRN Duration:</Text>
            <Text style={styles.value}>{prnDuration}</Text>
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        alignItems: 'center',
        padding: 30,
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    label: {
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 10,
    },
    value: {
        fontSize: 16,
        marginTop: 5,
    },
});

const PrnGenModal = ({ open, requestData, setOpen }) => {
    const [loading, setLoading] = useState(true)
    const [prn, setPrn] = useState('')
    const { userData } = useAuth()

    useEffect(() => {

        // setTimeout(() => {
        //     setPrn('2240000761452')
        //     setLoading(false)
        //     (async() => {
        //         await axios.post("https://govnet-api-proxy.vercel.app/prn-confirmation", {
        //             "prn": '2240000761452',
        //             "email": userData?.user?.email,
        //             "name": requestData.TaxPayerName,
        //             "amount": requestData.price,
        //             "package": requestData.package,
        //             "duration": requestData.duration,
        //         })
        //     })()
        //     setLoading(false)
        // }, 5000)
        (async () => {
            try {
                const response = await axios.post("https://publication-api-krx5u.ondigitalocean.app/api/get_prn", {
                    "TaxPayerName": requestData.TaxPayerName,
                    "AssessmentDate": currentDateFormat(),
                    "MobileNo": requestData.ContactNo,
                    "Email": userData?.email,
                    "AdditionalFees": "0",
                    "NoOfForms": "1",
                    "Amount": `${requestData?.price}`,
                    "ExpiryDays": "21",
                    "PaymentBankCode": "DTB",
                    "PaymentMode": "CASH",
                    "PaymentType": "DT",
                    "TaxPayerBankCode": "DTB",
                    "ReferenceNo": "string",
                    "SRCSystem": "ULRC",
                    "GrossAmount": "0",
                    "TIN": "1000943176",
                    "TaxHead": "ULRC100"
                });
                console.log("PRN response:", response?.data)
                const prnVal = response?.data?.result?.GetPRNResponse?.GetPRNResult?.PRN
                setPrn(prnVal)

                await axios.post("https://govnet-api-proxy.vercel.app/prn-confirmation", {
                    "prn": prnVal,
                    "email": userData?.user?.email,
                    "name": requestData.TaxPayerName,
                    "amount": requestData.price,
                    "package": requestData.package,
                    "duration": requestData.duration,
                })
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }

        })()
    }, [])

    return (
        <Modal
            title={loading ? "Please wait as we process your request..." : "PRN Generated"}
            centered
            footer={null}
            open={open}
            closable={false}
        >
            {loading ? <Steps
                items={[
                    {
                        title: 'Generating PRN',
                        status: 'process',
                        icon: <LoadingOutlined />,
                    }
                ]}
            /> : <>
                <div className='text-center mt-5'>Your Payment Reference Number is:</div>
                <div className='text-center text-4xl font-bold'>
                    {prn}
                </div>
            </>}
            {!!prn && <div className='text-center mt-5'>
                <Button onClick={() => setOpen(false)}>Ok</Button>
            </div>}
        </Modal>
    )
}

const MobileChoices = ({ setPayInfo, payInfo, selected, setSelected }) => {

    // const [selected, setSelected] = useState(0)

    const handleChange = ({ key, value }) => {
        setPayInfo({ ...payInfo, [key]: value })
    }

    const renderChoices = () => mobileChoices.map((choice, index) => {
        const isSelected = index === selected
        const selectedStyle = isSelected ? 'border-amber-400' : 'border-slate-200'
        const bgColor = choice.code === "mtn_momo_ug" ? 'bg-[#FFC240]' : 'bg-white'
        return (
            <div
                onClick={() => {
                    setSelected(index)
                }}
                className={`h-28 w-44 ${bgColor} bg-white border rounded-xl flex items-center justify-center cursor-pointer ${selectedStyle}`}
            >
                <img src={choice.image} alt="payment service" className='w-28' />
            </div>
        )
    })

    return (
        <>
            <div className='flex space-x-2 mb-4'>{renderChoices()}</div>
            {selected === 0 && <form>
                <div className='mb-4'>
                    <label className='block ml-1 mb-1'>Name</label>
                    <input
                        onChange={(e) => handleChange({ key: 'TaxPayerName', value: e.target.value })}
                        value={payInfo.TaxPayerName}
                        className='h-10 pl-2 w-96 rounded-md outline-none'
                        placeholder='ULRC User' />
                </div>
                <div className='mb-4'>
                    <label className='block ml-1 mb-1'>Mobile Number</label>
                    <input
                        onChange={(e) => handleChange({ key: 'ContactNo', value: e.target.value })}
                        value={payInfo.ContactNo}
                        className='h-10 pl-2 w-96 rounded-md outline-none'
                        placeholder='2567xxxxxxxx' />
                </div>
            </form>}
            {selected === 1 && <form>
                <div className='mb-4'>
                    <label className='block ml-1 mb-1'>Name</label>
                    <input
                        onChange={(e) => handleChange({ key: 'TaxPayerName', value: e.target.value })}
                        value={payInfo.TaxPayerName}
                        className='h-10 pl-2 w-96 rounded-md outline-none'
                        placeholder='ULRC User' />
                </div>
                <div className='mb-4'>
                    <label className='block ml-1 mb-1'>Mobile Number</label>
                    <input
                        onChange={(e) => handleChange({ key: 'ContactNo', value: e.target.value })}
                        value={payInfo.ContactNo}
                        className='h-10 pl-2 w-96 rounded-md outline-none'
                        placeholder='2567xxxxxxxx' />
                </div>
            </form>}
        </>
    )
}

const CardChoices = ({ setPayInfo, payInfo, selected, setSelected }) => {

    // const [selected, setSelected] = useState(0)

    const handleChange = ({ key, value }) => {
        setPayInfo({ ...payInfo, [key]: value })
    }

    const renderChoices = () => cardChoices2.map((choice, index) => {
        const isSelected = index === selected
        const selectedStyle = isSelected ? 'border-amber-400' : 'border-slate-200'
        return (
            <div
                onClick={() => setSelected(index)}
                className={`h-28 w-44 bg-white border rounded-xl flex items-center justify-center cursor-pointer ${selectedStyle}`}
            >
                <img src={choice.image} alt="payment service" className='w-28' />
            </div>
        )
    })

    return (
        <>
            <div className='flex space-x-2 mb-4'>{renderChoices()}</div>
            {selected === 0 && <form>
                <div className='mb-4'>
                    <label className='block ml-1 mb-1'>Name</label>
                    <input
                        onChange={(e) => handleChange({ key: 'TaxPayerName', value: e.target.value })}
                        value={payInfo.TaxPayerName}
                        className='h-10 pl-2 w-[100%] rounded-md outline-none'
                        placeholder='ULRC User' />
                </div>
            </form>}

            {selected === 1 && <form>
                <div className='mb-4'>
                    <label className='block ml-1 mb-1'>Name</label>
                    <input
                        onChange={(e) => handleChange({ key: 'TaxPayerName', value: e.target.value })}
                        value={payInfo.TaxPayerName}
                        className='h-10 pl-2 w-[100%] rounded-md outline-none'
                        placeholder='ULRC User' />
                </div>
               
            </form>}
        </>
    )
}

const OtherPayments = ({ setPayInfo, payInfo }) => {

    const handleChange = ({ key, value }) => {
        setPayInfo({ ...payInfo, [key]: value })
    }

    return (
        <div>
            <div className='text-xl mb-4 font-bold text-slate-400 text-center'>For Offline Payments</div>
            
        </div>
    )
}

const items = (setPayInfo, payInfo, selected, setSelected) => [
    {
        key: '1',
        label: 'Mobile Payment',
        children: <MobileChoices setPayInfo={setPayInfo} payInfo={payInfo} selected={selected} setSelected={setSelected} />,

    },
    {
        key: '2',
        label: 'Online Card Payment',
        children: <CardChoices setPayInfo={setPayInfo} payInfo={payInfo} selected={selected} setSelected={setSelected} />
    },
    {
        key: '3',
        label: 'Other Payment Modes',
        children: <OtherPayments setPayInfo={setPayInfo} payInfo={payInfo} selected={selected} setSelected={setSelected} />
    },
];

const PRNGeneration = ({
    prn, setPrn,
    phone, setPhone,
    name, setName
}) => {

    const [loading, setLoading] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)
    const [showPRNModal, setShowPRNModal] = useState(false)
    const [loadingPrn, setLoadingPrn] = useState(false)
    const [prnStatus, setPrnStatus] = useState(null)
    const [testPrn, setTestPrn] = useState(null)
    const { userData, subscription: subData, _setSubscription } = useAuth()
    const [selectedSubscription, setSelectedSubscription] = useState()

    useEffect(() => {

        if (userData?.user?.username) {
            setName(userData?.user?.username)
            !!userData?.user?.mobileNumber && setPhone(userData?.user?.mobileNumber)
        }

    }, [userData?.user?.username])

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!name || !phone) {
            alert("Please fill in all the fields")
            return false
        }

        if (name.length < 7) {
            alert("Name should be at least 7 characters")
            return false
        }

        if (phone.length !== 12) {
            alert("Please enter a valid phone number e.g 256712345678")
            return false
        }

        const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
        if (!regex.test(phone)) {
            alert("Please enter a valid phone number")
            return false
        }

        try {
            setLoading(true)
            // const response = await axios.post(`${BASE_URL}/get_prn`, {
            //     TaxPayerName: name,
            //     ContactNo: phone,
            //     AdditionalFees: 0,
            //     Amount: subData?.price,
            //     ExpiryDays: 21,
            //     PaymentBankCode: "STN",
            //     PaymentMode: "ONLINE",
            //     PaymentType: "DT",
            //     ReferenceNo: "string",
            //     SRCSystem: "ULRC",
            //     TIN: "1000943176",
            //     TaxHead: "URSB75",
            //     Email: userData?.user?.email
            // });

            const response = await axios.post(`${BASE_URL}/get_prn`, {
                "TaxPayerName": name,
                "AssessmentDate": currentDateFormat(),
                "MobileNo": phone,
                "Email": userData?.user?.email,
                "AdditionalFees": "0",
                "NoOfForms": "1",
                "Amount": `${subData?.price}`,
                "ExpiryDays": "21",
                "PaymentBankCode": "DTB",
                "PaymentMode": "CASH",
                "PaymentType": "DT",
                "TaxPayerBankCode": "DTB",
                "ReferenceNo": "string",
                "SRCSystem": "ULRC",
                "GrossAmount": "0",
                "TIN": "1000943176",
                "TaxHead": "ULRC100"
            });
            console.log(response.data)
            const myPrn = response.data?.result?.GetPRNResponse?.GetPRNResult?.PRN
            setPrn(myPrn)
            if (response.data) {
                const resp2 = await axios.post(`${API_URL}/prns`, {
                    data: {
                        prn: `${myPrn}`,
                        phoneNumber: phone,
                        userName: name,
                        prnAmount: subData?.price,
                        emailID: userData?.user?.email,
                        numberOfUsers: subData?.noOfUsers,
                        packageDuration: subData?.duration,
                        package: subData?.package
                    }
                })

                await axios.post("https://govnet-api-proxy.vercel.app/prn-confirmation", {
                    "prn": myPrn,
                    "email": userData?.user?.email,
                    "name": name,
                    "amount": subData?.price,
                    "package": subData?.package,
                    "duration": subData?.duration,
                })
            }
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong while creating your PRN...")
        } finally {
            setLoading(false)
        }
    };

    const _subscriptions = subscriptions.flat().filter(item => item.package !== "Enterprise")
    const formattedList = _subscriptions.map(sub => {
        return {
            value: `${sub.price}`,
            label: `${sub.package} ${sub.duration ? ("- " + sub.duration) : ""}`,
        }
    })
    const handleSelectChange = e => {
        const obj = _subscriptions.find(item => item.price == e)

        _setSubscription(obj)
    }

    const checkPRNStatus = async (e) => {
        e.preventDefault();
        if (!testPrn || testPrn.trim().length === 0) {
            alert("Please enter a valid PRN")
            return;
        }
        try {
            setShowPRNModal(true)
            setLoadingPrn(true)
            const response = await axios.post(`${BASE_URL}/prn_status`, {
                prn: `${testPrn}`
            })
            console.log("PRN STATUS:", response.data?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult)
            setPrnStatus(response.data?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult)
        } catch (error) {

        } finally {
            setLoadingPrn(false)
        }
    }

    const packageData = _subscriptions.find(item => item.value === subData?.price)

    return (
        <div>
            <div className='text-xl text-center mb-2 mt-3 font-bold text-slate-400'>PRN Generation and Status Check</div>
            <div className="border-b border-gray-200 flex justify-center mb-5">
                <div className="-mb-px flex space-x-8 cursor-pointer">
                    <div
                        // key={label}
                        className={`border-b-4 py-4 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 ${tabIndex === 0 ? 'border-indigo-500 text-indigo-500' : ''
                            }`}
                        onClick={() => setTabIndex(0)}
                    >
                        Generate PRN
                    </div>
                    <div
                        // key={label}
                        className={`border-b-4 py-4 px-1 text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 ${tabIndex === 1 ? 'border-indigo-500 text-indigo-500' : ''
                            }`}
                        onClick={() => setTabIndex(1)}
                    >
                        Check PRN Status
                    </div>
                </div>
            </div>

            {tabIndex === 0 && <>
                <div className='flex justify-center gap-10'>
                    <div className='mb-4'>
                        <label className='block ml-1 mb-1 text-gray-500'>Choose Package</label>
                        <Select
                            options={formattedList}
                            onChange={handleSelectChange}
                            defaultValue={`${subData?.price}`}
                            className='w-96 h-10'
                        />
                        <div className='mt-4'>
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <tbody>
                                    <tr className="bg-gray-50 border-b ">
                                        <td className="px-4 py-1 text-sm">
                                            Package
                                        </td>
                                        <td className="px-4 py-1">
                                            {subData?.package}
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b ">
                                        <td className="px-4 py-1 text-sm">
                                            Duration
                                        </td>
                                        <td className="px-4 py-1">
                                            {subData?.duration ? subData.duration : "N/A"}
                                        </td>
                                    </tr>
                                    <tr className=" bg-gray-50 border-b">
                                        <td className="px-4 py-1 text-sm">
                                            Price
                                        </td>
                                        <td className="px-4 py-1">
                                            {`${subData?.price ? subData?.price?.toLocaleString() + "/=" : "N/A"}`}
                                        </td>
                                    </tr>
                                    <tr className="bg-white border-b ">
                                        <td className="px-4 py-1 text-sm">
                                            Number of users
                                        </td>
                                        <td className="px-4 py-1">
                                            {`${subData?.user} ${subData?.noOfUsers}`}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <form className='flex items-center flex-col mb-10' onSubmit={handleSubmit}>
                        <div className='mb-4'>
                            <label className='block ml-1 mb-1'>Name</label>
                            <input
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                                className='h-10 pl-2 w-96 rounded-md outline-none border border-gray-300'
                                placeholder='ULRC User' />
                        </div>
                        <div className='mb-4'>
                            <label className='block ml-1 mb-1'>Mobile Number</label>
                            <input
                                value={phone}
                                onChange={(event) => setPhone(event.target.value)}
                                className='h-10 pl-2 w-96 rounded-md outline-none border border-gray-300'
                                placeholder='2567xxxxxxxx' />
                        </div>
                        <button
                            disabled={loading}
                            className='w-96 h-10 rounded-md bg-blue-500 text-white mt'
                        >
                            {loading ? "Please Wait..." : 'Generate PRN'}
                        </button>
                    </form>
                </div>

            </>}

            {tabIndex === 1 && <>
                {/* <div className='text-xl text-center mb-2 mt-3 font-bold text-slate-400'>CHECK PRN STATUS</div> */}
                <form className='flex justify-center items-center flex-col mb-20'>
                    <label className='block ml-1 mb-1'>PRN <span className='text-sm'>(If you have a prn from the system and you want to pay to it)</span></label>
                    <input
                        // value={name}
                        onChange={(event) => setTestPrn(event.target.value)}
                        className='h-10 pl-2 w-96 rounded-md outline-none border border-gray-300 mb-3'
                        placeholder='Enter your PRN'
                    />
                    <button
                        disabled={loadingPrn}
                        onClick={checkPRNStatus}
                        className='w-96 h-10 rounded-md bg-blue-500 text-white mt'
                    >
                        {loadingPrn ? "Please Wait..." : 'Check PRN Status'}
                    </button>
                </form>
                <PrnStatus2 isModalOpen={showPRNModal} handleCancel={() => setShowPRNModal(false)} loading={loadingPrn} prnStatus={prnStatus} setPrn={setPrn} testPrn={testPrn} />
            </>}

            <div className='w-full bg-gray-300 bg-opacity-25 flex justify-center items-center pt-3 mt-5'>
                <div className='text-xl text-center mb-2 mt-3 font-bold text-slate-700'>
                    Choose Payment Method
                    <div className='text-sm text-center text-slate-500'>
                        After selecting or generating PRN, you will be able to choose a
                        <br />
                        payment method to make payment
                        <br />
                        <span className='text-slate-500'>
                            (You can choose from multiple payment methods)
                            <br />
                            <br />                            
                        </span>
                        
                        <span className='text-slate-500'>
                            If you have any questions, please contact us
                            <br />
                            <br />
                            <MdEmail size={25} className='inline-block mr-2' />
                            sales@ulrc.go.ug
                            <span>
                                <br />
                                <MdPhone size={25} className='inline-block mr-2' />
                                +256782827337
                                <br />
                                <MdLocationOn size={25} className='inline-block mr-2' />
                                Kampala, Uganda
                                <br />
                                <br />
                            </span>
                        </span>
                    </div>
                </div>
            </div>
        </div >
    )
}

const CardPayment2 = () => {
    const [subscriptionData, setSubscriptionData] = useState({})
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [open, setOpen] = useState(false)
    const [openOther, setOpenOther] = useState(false)
    const [prn, setPrn] = useState(null)
    const [payInfo, setPayInfo] = useState(initialPayInfo)
    const [currentTab, setCurrentTab] = useState(1)
    const [selected, setSelected] = useState(0)
    const [paying, setPaying] = useState(false)
    const [showPRNBtn, setShowPRNBtn] = useState(false)
    const [showPRNModal, setShowPRNModal] = useState(false)
    const [loadingPrn, setLoadingPrn] = useState(false)
    const [prnStatus, setPrnStatus] = useState(null)
    const { userData, subscriptionData: subData, subscription: subData2, setSubscription } = useAuth()
    const navigate = useNavigate()

    const user = userData?.user;
    const username = user?.username;

    React.useEffect(() => {
        /// Temp work around for route protection
        if (!userData) navigate("/")
    }, [userData])

    React.useEffect(() => {
        if (!!phone && !!name) {
            setPayInfo({
                ...initialPayInfo,
                TaxPayerName: name,
                ContactNo: phone
            })
        }
    }, [phone, name])

    useEffect(() => {
        if (Number(currentTab) === 1) {
            setPayInfo({ ...initialPayInfo, method: paymentMethods.mm, code: mobileChoices[selected].code, TaxPayerName: name || username })
        } else if (Number(currentTab) === 2) {
            setPayInfo({ ...initialPayInfo, method: paymentMethods.card, code: cardChoices2[selected].code, TaxPayerName: name || username })
        }
    }, [currentTab, selected, username, name, phone])

    useEffect(() => {
        const subscription = localStorage.getItem('subscription')
        const publicationLink = localStorage.getItem("publicationLink") || "/"

        if (!!subData) {
            navigate(publicationLink)
        }
        if (JSON.parse(subscription)) {
            setSubscriptionData(JSON.parse(subscription))
        } else {
            navigate("/")
        }
    }, [])

    const onChange = (key) => {
        setCurrentTab(key)
    };

    const validateUser = () => {
        const { TaxPayerName, ContactNo } = payInfo

        if(Number(currentTab) === 2) {
            if (!TaxPayerName) {
                alert("Please fill in your name")
                return false
            }

            return true;
        }

        if (!TaxPayerName || !ContactNo) {
            alert("Please fill in all the fields")
            return false
        }

        if (TaxPayerName.length < 7) {
            alert("Name should be at least 7 characters")
            return false
        }

        if (ContactNo.length !== 12) {
            alert("Please enter a valid phone number e.g 256712345678")
            return false
        }

        const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;
        if (!regex.test(ContactNo)) {
            alert("Please enter a valid phone number")
            return false
        }

        return true
    }

    const handlePayment2 = async () => {
        const requestData = { ...payInfo, ...subData2 }
        if (Number(currentTab) === 3) {
            // setOpenOther(true)
            // Sends Email
            toast.success("Your Payment Slip has been sent to your email!");
            await axios.post(`${PAYMENT_SERVER}/prn-confirmation`, {
                prn,
                email: userData?.user?.email,
                package: subData2?.package,
                duration: subData2?.duration,
                amount: subData2?.price,
            })
            return;
        }
        if (!validateUser()) return;
        // setOpen(true)
        try {
            setPaying(true)
            toast.success("Payment Initiated, Please wait for prompt!");

            // let response

            // try{
            //     response = await axios.post(API_PAY_URL, {
            //         "merchant_reference": "auto",
            //         "transaction_method": "MOBILE_MONEY",
            //         "currency": "UGX",
            //         "amount": requestData.price,
            //         // "amount": 50000,
            //         "provider_code": requestData.code,
            //         "msisdn": requestData.ContactNo,
            //         "customer_name": requestData.TaxPayerName,
            //         prn: `${prn}`,
            //         // prn: '2240013417179',
            //         "description": "proxy request"
            //     })
            // } catch (error) {
            //     const errMessage = error?.response?.data?.detail?.message
            //     console.log("ERROR:", error, errMessage)
            //     errMessage && toast.error(errMessage)
            // }

            let response;
            const basePayload = {
                merchant_reference: "auto",
                currency: "UGX",
                amount: requestData.price,
                customer_name: requestData.TaxPayerName,
                prn: `${prn}`,
                description: "ULRC Publications Access"
            }

            if (Number(currentTab) === 1) {
                // Mobile Money Payment
                toast.success("Payment Initiated, Please wait for prompt!");
                try {
                    response = await axios.post(API_PAY_URL, {
                        ...basePayload,
                        transaction_method: "MOBILE_MONEY",
                        provider_code: requestData.code,
                        msisdn: requestData.ContactNo,
                    })
                } catch (error) {
                    const errMessage = error?.response?.data?.detail?.message
                    console.log("ERROR:", error, errMessage)
                    errMessage && toast.error(errMessage)
                }
            } else if (Number(currentTab) === 2) {
                // Card Payment
                try {
                    response = await axios.post(API_PAY_URL, {
                        ...basePayload,
                        transaction_method: "CARD",
                        provider_code: "card_ug",
                        msisdn: requestData.ContactNo,
                        redirect_url: `https://publications.ulrc.go.ug/cart/payment-success` // Adjust this URL as needed
                    })

                    // If we get a payment URL, redirect to it
                    if (response?.data?.data?.payment_url) {
                        window.location.href = response.data.data.payment_url;
                        return;
                    }
                } catch (error) {
                    const errMessage = error?.response?.data?.detail?.message
                    console.log("ERROR:", error, errMessage)
                    errMessage && toast.error(errMessage)
                    return;
                }
            }

            const paymentData = {
                "email": userData?.user?.email,
                "prn": prn,
                "package": requestData?.package,
                "subStart": new Date(`${requestData.startDate}`).toLocaleDateString(),
                "subEnd": new Date(`${subscriptionEnd(requestData.startDate, requestData.duration)}`).toLocaleDateString(),
                "amount": requestData?.price,
                "name": userData?.user?.username,
                "dateOfReciept": new Date().toLocaleDateString().split(",")[0],
                "provider": payInfo.code
            }

            console.log("RESPONSE:", response?.data?.data)
            if (response?.data?.data) {

                try {
                    setShowPRNBtn(true)
                    const _prnStatus = await checkingPRN(prn);
                    console.log("_prnStatus", _prnStatus, _prnStatus?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult?.StatusCode);
                    if (_prnStatus.result?.CheckPRNStatusResponse?.CheckPRNStatusResult?.StatusCode === 'T') {
                        try {
                            await createSubscription();
                            toast.success("Payment Successful and Subscription Created!");
                            await sendPaymentEmail(paymentData);
                            navigate('/cart/payment-success');
                        } catch (error) {
                            toast.error("Error creating subscription");
                        }
                    } else {
                        toast.error("Payment Failed, Please try again!");
                    }
                } catch (error) {
                    toast.error("Payment Failed, Please try again!");
                    console.error('Error checking PRN status:', error);
                }

            }
        } catch (err) {
            console.log("ERROR:", err)
        } finally {
            setPaying(false)
        }
    }

    const createSubscription = async () => {
        const requestData = { ...payInfo, ...subData2 }

        const subscriberData = {
            subscriptionName: requestData.package,
            subscriptionType: requestData.duration,
            amount: `${requestData.price}`,
            numberOfUsers: requestData.noOfUsers,
            subscriptionStart: requestData.startDate,
            subscriptionEnd: subscriptionEnd(requestData.startDate, requestData.duration),
            paymentRef: "",
            userEmail: userData?.user?.email,
            PRN: `${prn}`,
            userPhone: requestData.ContactNo
        }

        try {
            const response = await axios.post(`${API_URL}/subscriptions`, {
                data: subscriberData
            })
            console.log("SUBSCRIPTION RESPONSE:", response?.data)
            setSubscription(subscriberData)
        } catch (error) {
            console.error('Error creating subscription', error)
        }
    }

    const sendPaymentEmail = async (payData) => {
        try {
            const response = await axios.post(`${PAYMENT_SERVER}/payment-confirmation`, payData);
            console.log("RESPONSE:", response?.data?.data)
        } catch {
            console.log("ERROR")
        }
    }

    const handlePayment3 = async () => {
        // This is for the dev env
        const requestData = { ...payInfo, ...subData2 }
        if (Number(currentTab) === 3) {
            toast.success("Your Payment Slip has been sent to your email!");
            await axios.post(`${PAYMENT_SERVER}/prn-confirmation`, {
                prn,
                email: userData?.user?.email,
                package: subData2?.package,
                duration: subData2?.duration,
                amount: subData2?.price,
            })
            return;
        }
        if (!validateUser()) return;


        try {
            setPaying(true)

            const response = await axios.post(DEV_PAY_URL, {
                "merchant_reference": "auto",
                "transaction_method": "MOBILE_MONEY",
                "currency": "UGX",
                "amount": requestData.price,
                "provider_code": requestData.code,
                "msisdn": requestData.ContactNo,
                "customer_name": requestData.TaxPayerName,
                prn,
                "description": "proxy request"
            })

            const paymentData = {
                "email": userData?.user?.email,
                "prn": prn,
                "package": requestData?.package,
                "subStart": new Date(`${requestData.startDate}`).toLocaleDateString(),
                "subEnd": new Date(`${subscriptionEnd(requestData.startDate, requestData.duration)}`).toLocaleDateString(),
                "amount": requestData?.price,
                "name": userData?.user?.username,
                "dateOfReciept": new Date().toLocaleDateString().split(",")[0],
                "provider": payInfo.code
            }

            console.log("RESPONSE:", response?.data?.data)
            if (response?.data?.data) {
                setShowPRNBtn(true)
                try {
                    const _prnStatus = await checkingPRN(prn);
                    console.log("_prnStatus", _prnStatus, _prnStatus?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult?.StatusCode);
                    if (_prnStatus.result?.CheckPRNStatusResponse?.CheckPRNStatusResult?.StatusCode === 'T') {
                        try {
                            await createSubscription();
                            toast.success("Payment Successful and Subscription Created!");
                            await sendPaymentEmail(paymentData);
                            navigate('/cart/payment-success');
                        } catch (error) {
                            toast.error("Error creating subscription");
                        }
                    } else {
                        toast.error("Payment Failed, Please try again!");
                    }
                } catch (error) {
                    toast.error("Payment Failed, Please try again!");
                    console.error('Error checking PRN status:', error);
                }
            }
        } catch (err) {
            console.log("ERROR:", err)
        } finally {
            setPaying(false)
        }
    }

    const checkPRNStatus = async () => {
        try {
            setShowPRNModal(true)
            setLoadingPrn(true)
            const response = await axios.post(`${BASE_URL}/prn_status`, {
                prn: `${prn}`
            })
            console.log("PRN STATUS:", response.data?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult)
            setPrnStatus(response.data?.result?.CheckPRNStatusResponse?.CheckPRNStatusResult)
        } catch (error) {

        } finally {
            setLoadingPrn(false)
        }
    }
    
    const enabled = Number(currentTab) === 1 || Number(currentTab) === 2 || Number(currentTab) === 3

    const otherPaymentTab = Number(currentTab) === 3

    return (
        <div className='h-[120%] bg-bgGrey pt-2 flex flex-col justify-between'>
            <Spin spinning={paying} size='large' fullscreen tip="Processing Payment, please wait..." />
            <div>
                <div className='container mx-auto'>
                    <AuthHeader2 />
                </div>

                <AnimatePresence>
                    {
                        !prn ? (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <PRNGeneration {...{
                                    prn, setPrn,
                                    phone, setPhone,
                                    name, setName
                                }} />
                            </motion.div>
                        ) : (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.5, delay: 0.7 }}
                            >
                                <div className='flex items-center flex-col mb-14'>
                                    <Tabs defaultActiveKey="1" items={items(setPayInfo, payInfo, selected, setSelected)} onChange={onChange} />
                                    <div className='border mt-6 w-110 p-4 rounded-md'>
                                        <div className='flex justify-between'>
                                            <span className='text-slate-400 font-semibold'>PACKAGE</span>
                                            <span className='text-slate-400 font-bold'>{subData2?.package}</span>
                                        </div>
                                        <div className='flex justify-between'>
                                            <span className='text-slate-400 font-semibold'>DURATION</span>
                                            <span className='text-slate-400 font-bold'>{subData2?.duration}</span>
                                        </div>
                                        <div className='flex justify-between'>
                                            <span className='text-slate-400 font-semibold'>AMOUNT</span>
                                            <span className='text-slate-400 font-bold'>{`${subData2?.price ? subData2?.price?.toLocaleString() + "/=" : "N/A"}`}</span>
                                        </div>
                                        <div className='flex justify-between'>
                                            <span className='text-slate-400 font-semibold'>PRN</span>
                                            <span className='text-slate-400 font-bold'>{prn}</span>
                                        </div>
                                        <div className='flex justify-between'>
                                            <span className='text-slate-400 font-semibold'>PRN EXPIRY DATE</span>
                                            <span className='text-slate-400 font-bold'>{new Date(Date.now() + 21 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' })}</span>
                                        </div>
                                    </div>
                                    <div className='w-110 mt-6 px-5 flex justify-between'>
                                        <span>TOTAL</span>
                                        <span className='font-bold'>{`${subData2?.price?.toLocaleString()} /=`}</span>
                                    </div>

                                    <button
                                        disabled={!enabled}
                                        className={`w-110 mt-6  ${enabled ? "bg-lightGreen" : "bg-gray-300 cursor: not-allowed;"} text-white py-3 rounded-md font-semibold`}
                                        onClick={handlePayment2}
                                    >{(showPRNBtn && !otherPaymentTab) ? "Try again" : (paying ? "Paying" : (!otherPaymentTab ? `Pay ${subData2?.price?.toLocaleString()} /=` : "Get Payment Slip"))}</button>
                                    {showPRNBtn && !otherPaymentTab && <button
                                        onClick={checkPRNStatus}
                                        className={`w-110 mt-2 text-white py-3 rounded-md font-semibold bg-sky-600`}
                                    >Check PRN Status</button>}
                                    {/* {(Number(currentTab) === 2) && <span className='text-sm text-red-500 text-center'>This service is currently unavailable and undergoing maintenance, <br /> please try again later</span>} */}
                                    {(Number(currentTab) === 2) && <span className='text-sm text-blue-500 text-center mt-3'>You will be redirected to a secure payment page to complete your transaction</span>}
                                    {/* <PDFDownloadLink document={
                                        <PaymentSlip {...{
                                            packageName: subscriptionData?.package,
                                            duration: subscriptionData?.duration,
                                            totalAmount: subscriptionData?.price,
                                            prn,
                                            prnDuration: new Date(Date.now() + 21 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { day: '2-digit', month: 'long', year: 'numeric' })
                                        }} />}
                                        fileName="payment-slip.pdf"
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                            padding: "10px 20px",
                                            border: "1px solid black",
                                            borderRadius: "5px",
                                            cursor: "pointer",
                                          }}
                                        >
                                        {({ loading }) => (
                                            loading ? 'Loading document...' : 'Download now!'
                                        )}
                                    </PDFDownloadLink> */}
                                </div>
                            </motion.div>
                        )
                    }
                </AnimatePresence>
            </div>
            <Footer />
            <PrnStatus isModalOpen={showPRNModal} handleCancel={() => setShowPRNModal(false)} loading={loadingPrn} prnStatus={prnStatus} />
            {openOther && <PrnGenModal open={openOther} setOpen={setOpenOther} requestData={{ ...payInfo, ...subData2 }} />}
            {open && <PaymentModal open={open} requestData={{ ...payInfo, ...subData2 }} setOpen={setOpen} prnVal={prn} />}
        </div>
    )
}

export default CardPayment2;
