import React, { useState } from 'react'
import { useAuth } from '../Auth';
import { API_URL } from '../envs';
import useInviteUser from '../hooks/useInviteUser';

// const inviteUrl = process.env.NODE_ENV === 'production' ? 'https://govnet-api-proxy.vercel.app/invite' : 'http://localhost:3003/invite';
const inviteUrl = 'https://govnet-api-proxy.vercel.app/invite';

const InviteUserDialog = ({ closeModal, fetchUsers, fetchInvitees }) => {
    // const [loader, setLoader] = useState(false)

    const { userData } = useAuth()
    const [email, setEmail] = useState('');
    const { handleSubmit, loader } = useInviteUser(API_URL, userData, inviteUrl, fetchUsers, fetchInvitees, closeModal);

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-slate-900/75 flex justify-center items-center">
            <form
                className="bg-white rounded-lg w-[500px] max-w-full max-h-full overflow-y-auto"
                onSubmit={handleSubmit}
            >
                <div className="flex justify-between items-center px-5 py-4 border-b border-[color:var(--lighter-grey-strokes,#ECEDED)]">
                    <div className="text-neutral-800 text-lg font-semibold">
                        Invite New User
                    </div>
                    <div
                        className="text-neutral-800 text-lg font-semibold cursor-pointer"
                        onClick={closeModal}
                    >
                        X
                    </div>

                </div>
                <div className="px-5 py-4">
                    <div className="text-neutral-800 text-sm leading-5 mb-2">
                        Email
                    </div>
                    <input
                        type="email"
                        name='email'
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        placeholder='Enter User Email'
                        title="Please enter a valid email address"
                        required
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        className="border border-[color:var(--lighter-grey-strokes,#ECEDED)] rounded-lg w-full px-3 py-2 text-sm leading-5"
                    />
                </div>
                <div className="px-5 py-4 flex justify-end">
                    <button
                        disabled={loader}
                        onClick={() => handleSubmit(email)}
                        className="bg-bgBlue text-white py-2 px-5 rounded-lg"
                    >
                        {loader ? "Wait..." : "Invite"}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default InviteUserDialog;
