import React, { useState, useEffect, useRef } from 'react'
import { useAuth } from '../Auth';
import axios from 'axios';
import { API_URL } from '../envs';
import { Popconfirm, Space, Spin, Table, Button, Tabs } from 'antd'
import { toast } from 'react-toastify';
import qs from 'qs';
import Papa from 'papaparse';
import { InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import useInviteUser from '../hooks/useInviteUser';
import { encodeQueryData } from '../utils/methods';
import InviteUserDialog from './InviteUserDialog';

// const inviteUrl = process.env.NODE_ENV === 'production' ? 'https://govnet-api-proxy.vercel.app/invite' : 'http://localhost:3003/invite';
const inviteUrl = 'https://govnet-api-proxy.vercel.app/invite';

function UserManagement() {
    const [showInviteUserDialog, setShowInviteUserDialog] = React.useState(false)
    const [users, setUsers] = React.useState([])
    const [invitations, setInvitations] = React.useState([])

    const [loading, setLoading] = React.useState(false)
    const [disableAdd, setDisableAdd] = React.useState(false)
    const [activeTab, setActiveTab] = useState('1')
    const [csvLoading, setCsvLoading] = useState(false)
    const [uploadProgress, setUploadProgress] = useState(0)
    
    // Pagination states
    const [usersPagination, setUsersPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    })
    const [invitationsPagination, setInvitationsPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    })
    
    const { userData, subscriptionData } = useAuth()
    const fileInputRef = useRef(null)

    // User columns
    const userColumns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Name',
            dataIndex: 'name'
        },
        {
            title: 'Email',
            dataIndex: 'email'
        },
        {
            title: 'Confirmed',
            dataIndex: 'confirmed',
            render: (text) => text === 'true' ? 'Yes' : 'No'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm
                        title="Delete user"
                        description="Are you sure to delete this user?"
                        onConfirm={() => deleteUser(record)}
                        okButtonProps={{ className: 'bg-red-600 hover:bg-red-400' }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a className='text-red-800 hover:text-red-400'>Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    // Invitation columns
    const invitationColumns = [
        {
            title: 'ID',
            dataIndex: 'id'
        },
        {
            title: 'Email',
            dataIndex: 'InviteeEmail'
        },
        {
            title: 'Invited On',
            dataIndex: 'createdAt',
            render: (text) => new Date(text).toLocaleDateString()
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: () => 'Pending'
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Popconfirm
                        title="Delete invitation"
                        description="Are you sure to delete this invitation?"
                        onConfirm={() => deleteInvitation(record)}
                        okButtonProps={{ className: 'bg-red-600 hover:bg-red-400' }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <a className='text-red-800 hover:text-red-400'>Delete</a>
                    </Popconfirm>
                </Space>
            ),
        },
    ]

    const deleteInvitation = async (invitation) => {
        try {
            setLoading(true)
            await axios.delete(`${API_URL}/invitations/${invitation.id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
            toast.success('Invitation deleted successfully')
            fetchInvitations(invitationsPagination.current, invitationsPagination.pageSize)
        } catch (error) {
            console.log(error)
            toast.error('Failed to delete invitation')
        } finally {
            setLoading(false)
        }
    }

    const deleteUser = async (user) => {
        if (JSON.parse(user.confirmed) && Number(user.subscribedTo) === userData?.user?.id) {
            try {
                await deleteSubscriber(user)
                toast.success('User deleted successfully')
                fetchUsers()
            } catch (error) {
                console.log("ERROR:", error)
                toast.error('Failed to delete user')
            }
        }
    }

    const deleteEntry = async (entry) => {
        try {
            await axios.delete(`${API_URL}/subscriptions/${entry.id}`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
        } catch (error) {
            console.log(error);
        }
    }

    const deleteSubscriber = async (user) => {
        try {
            setLoading(true)
            await axios.put(`${API_URL}/users/${user.id}`, { subscribedTo: null });

            try {
                const filters = {
                    'filters[PRN][$eq]': subscriptionData.PRN,
                    'filters[userEmail][$eq]': user?.email,
                }
                const query = encodeQueryData(filters)

                const resp = await axios.get(`${API_URL}/subscriptions?${query}`);
                const entries = resp.data.data;

                for (const subscription of entries) {
                    await deleteEntry(subscription)
                }

                // Search in invitees list for email and delete subsequent entries
                const InviteeFilters = {
                    'filters[InviteeEmail][$eq]': user?.email,
                }
                const inviteeQuery = encodeQueryData(InviteeFilters)
                const inviteeResp = await axios.get(`${API_URL}/invitations?${inviteeQuery}`);
                const invitees = inviteeResp.data.data;
                for (const invitee of invitees) {
                    await deleteInvitation(invitee)
                }
            } catch (error) {
                console.log(error);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    React.useEffect(() => {
        fetchUsers()
        fetchInvitations(1, invitationsPagination.pageSize)
    }, [])

    const fetchInvitations = async (page = 1, pageSize = 10) => {
        try {
            setLoading(true)
            const query = qs.stringify({
                filters: {
                    InviterID: {
                        '$eq': userData?.user?.id
                    }
                }
            }, {
                encodeValuesOnly: true,
            });
            
            const start = (page - 1) * pageSize
            const response = await axios.get(`${API_URL}/invitations?${query}&pagination[start]=${start}&pagination[limit]=${pageSize}`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
            
            const data = response?.data?.data || []
            const formattedData = data?.map(item => ({ 
                ...item?.attributes, 
                id: item?.id,
                key: item?.id
            }))
            
            setInvitations(formattedData)
            setInvitationsPagination({
                ...invitationsPagination,
                current: page,
                total: response?.data?.meta?.pagination?.total || 0
            })
        } catch (error) {
            console.log(error)
            toast.error('Failed to fetch invitations')
        } finally {
            setLoading(false)
        }
    }

    const fetchUsers = async () => {
        try {
            setLoading(true)
            const query = qs.stringify({
                filters: {
                    subscribedTo: {
                        '$eq': userData?.user?.id
                    }
                }
            }, {
                encodeValuesOnly: true,
            });
            
            const response = await axios.get(`${API_URL}/users?${query}`, {
                headers: {
                    Authorization: `Bearer ${userData?.jwt}`,
                },
            });
            
            const _users = await response.data;
            const filteredUsers = _users?.map(user => {
                return {
                    ...user,
                    name: user.username,
                    confirmed: `${user.confirmed}`,
                    key: user.id
                }
            });
            
            setUsers(filteredUsers);
            setUsersPagination({
                ...usersPagination,
                total: filteredUsers.length
            })
        } catch (error) {
            console.log(error);
            toast.error('Failed to fetch users')
        } finally {
            setLoading(false)
        }
    }

    const { handleSubmit, loader } = useInviteUser(API_URL, userData, inviteUrl, fetchUsers, () => fetchInvitations(1, invitationsPagination.pageSize), () => {});

    useEffect(() => {
        const str = subscriptionData?.numberOfUsers || "0"
        const num = str.match(/\d+/)?.[0] || "0";
        const numericValue = parseInt(num, 10);

        const totalUsers = users.length + invitations.length;
        if (totalUsers >= (numericValue - 1)) {
            setDisableAdd(true);
        } else {
            setDisableAdd(false);
        }
    }, [subscriptionData, users, invitations])

    const handleUserTableChange = (pagination) => {
        setUsersPagination({
            ...usersPagination,
            current: pagination.current
        })
    }

    const handleInvitationTableChange = (pagination) => {
        fetchInvitations(pagination.current, pagination.pageSize)
    }

    // Progress bar for CSV upload
    const renderProgressBar = () => {
        if (!csvLoading) return null;

        const getProgressColor = () => {
            if (uploadProgress < 30) return 'bg-yellow-500';
            if (uploadProgress < 70) return 'bg-blue-500';
            if (uploadProgress < 100) return 'bg-green-500';
            return 'bg-green-600';
        };

        const getProgressText = () => {
            if (uploadProgress < 30) return 'Reading file...';
            if (uploadProgress < 70) return 'Parsing emails...';
            if (uploadProgress < 90) return 'Validating emails...';
            if (uploadProgress < 100) return 'Sending invitations...';
            return 'Complete!';
        };

        return (
            <div className="fixed top-0 left-0 w-full h-full bg-slate-900/75 flex justify-center items-center z-50">
                <div className="bg-white rounded-lg p-6 w-[500px] max-w-full">
                    <h3 className="text-lg font-semibold mb-4">Processing CSV File</h3>
                    <div className="w-full bg-gray-200 rounded-full h-4 mb-2">
                        <div
                            className={`h-4 rounded-full ${getProgressColor()} transition-all duration-300`}
                            style={{ width: `${uploadProgress}%` }}
                        ></div>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600">{getProgressText()}</span>
                        <span className="text-sm font-medium">{uploadProgress}%</span>
                    </div>
                </div>
            </div>
        );
    };

    // const [csvLoading, setCsvLoading] = useState(false);
    // const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setCsvLoading(true);
            setUploadProgress(10); // Start progress

            // Simulate initial parsing progress
            const progressInterval = setInterval(() => {
                setUploadProgress(prev => {
                    const newProgress = prev + Math.floor(Math.random() * 5);
                    return newProgress > 70 ? 70 : newProgress; // Cap at 70% until actual processing completes
                });
            }, 200);

            Papa.parse(file, {
                header: true,
                complete: async (results) => {
                    clearInterval(progressInterval);
                    setUploadProgress(80); // Parsing complete
                    
                    const newEmails = results.data
                        .filter(row => row && (row.email || row.Email))
                        .map(user => user.email || user.Email);
                    
                    // Check if we have valid emails
                    if (newEmails.length === 0) {
                        setCsvLoading(false);
                        toast.error('No valid emails found. Please follow the template format with "email" column.');
                        return;
                    }
                    
                    // Validate email format
                    const validEmails = newEmails.filter(email => 
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)
                    );
                    
                    if (validEmails.length < newEmails.length) {
                        toast.warning(`${newEmails.length - validEmails.length} invalid email(s) were removed.`);
                    }
                    
                    const totalUsers = users.length + invitations.length;
                    const str = subscriptionData?.numberOfUsers || "0";
                    const num = str.match(/\d+/)?.[0] || "0";
                    const numericValue = parseInt(num, 10);
                    const maxEmailsToAdd = numericValue - (totalUsers + 1);
                    
                    const emailsToAdd = validEmails.slice(0, maxEmailsToAdd);
                    
                    // If no valid emails to add after filtering
                    if (emailsToAdd.length === 0) {
                        setCsvLoading(false);
                        toast.error('No valid emails to add. Please check your CSV file format.');
                        return;
                    }

                    setUploadProgress(90); // Start sending invites

                    try {
                        await Promise.all(emailsToAdd.map(async (email) => {
                            await handleSubmit(email, true);
                        }));
                        
                        setUploadProgress(100); // Complete
                        await fetchUsers();
                        await fetchInvitations(1, invitationsPagination.pageSize);
                        toast.success('User Invitations sent Successfully');
                    } catch (error) {
                        console.error("Error submitting users:", error);
                        toast.error('Error submitting users');
                    } finally {
                        setCsvLoading(false);
                    }
                },
                error: (error) => {
                    clearInterval(progressInterval);
                    setCsvLoading(false);
                    console.error("Error parsing CSV file:", error);
                    toast.error('Error parsing CSV file');
                },
            });
        }
    };

    const downloadTemplate = () => {
        const link = document.createElement('a');
        link.href = '/emails.csv';
        link.download = 'template.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    // Get paginated users for client-side pagination
    const getPaginatedUsers = () => {
        const { current, pageSize } = usersPagination;
        const startIndex = (current - 1) * pageSize;
        return users.slice(startIndex, startIndex + pageSize);
    };

    const items = [
        {
            key: '1',
            label: `Users (${users.length})`,
            children: (
                <Spin spinning={loading && activeTab === '1'} tip="Loading Users...">
                    <Table
                        columns={userColumns}
                        dataSource={getPaginatedUsers()}
                        pagination={{
                            current: usersPagination.current,
                            pageSize: usersPagination.pageSize,
                            total: usersPagination.total,
                            showSizeChanger: false
                        }}
                        onChange={handleUserTableChange}
                    />
                </Spin>
            ),
        },
        {
            key: '2',
            label: `Invitations (${invitationsPagination.total})`,
            children: (
                <Spin spinning={loading && activeTab === '2'} tip="Loading Invitations...">
                    <Table
                        columns={invitationColumns}
                        dataSource={invitations}
                        pagination={{
                            current: invitationsPagination.current,
                            pageSize: invitationsPagination.pageSize,
                            total: invitationsPagination.total,
                            showSizeChanger: false
                        }}
                        onChange={handleInvitationTableChange}
                    />
                </Spin>
            ),
        },
    ];

    return (
        <div className="mt-4 px-5 pb-8 max-md:max-w-full max-md:mt-10">
            {renderProgressBar()}
            <div className="flex justify-between mb-3">
                <h1 className="text-neutral-800 text-lg font-semibold -mr-5 max-md:max-w-full mb-5">
                    Subscription Management
                </h1>
                <div>
                    <span className='font-bold'>{users.length + invitationsPagination.total + 1}</span> / <span>{subscriptionData?.numberOfUsers || 0}</span>
                </div>
            </div>
            
            <Tabs 
                activeKey={activeTab} 
                onChange={setActiveTab} 
                items={items}
                className="mb-6"
            />

            {!disableAdd && (
                <div className="flex justify-between items-center gap-2 mt-5">
                    <Button
                        icon={<InfoCircleOutlined />}
                        onClick={downloadTemplate}
                        className="text-[#1d234f] rounded h-10 ml-2"
                    >
                        Download CSV Template
                    </Button>
                    <div className='flex justify-center gap-2'>
                        <div className="">
                            <Button
                                icon={<UploadOutlined />}
                                onClick={triggerFileInput}
                                className="text-[#1d234f] rounded h-10"
                            >
                                Upload CSV
                            </Button>
                            <input
                                type="file"
                                accept=".csv"
                                onChange={handleFileUpload}
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                            />
                        </div>
                        <button
                            className="bg-bgBlue text-white py-2 px-5 rounded"
                            onClick={() => setShowInviteUserDialog(true)}
                        >Add New User</button>
                    </div>
                </div>
            )}
            {showInviteUserDialog && (
                <InviteUserDialog 
                    closeModal={() => setShowInviteUserDialog(false)} 
                    fetchUsers={fetchUsers} 
                    fetchInvitees={() => fetchInvitations(1, invitationsPagination.pageSize)} 
                />
            )}
        </div>
    )
}

export default UserManagement;
