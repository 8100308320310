import * as React from "react";
import Footer from "./Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo2 from '../ulrc_logo.png';
import { FaCircleCheck } from "react-icons/fa6";
import ConfettiExplosion from 'react-confetti-explosion';
import { AuthHeader2 } from "./UnAuthHeader";
import { useAuth } from "../Auth";
import { Alert } from "antd";

function generateTenDigitNumber() {
  var number = '';
  while (number.length < 10) {
      number += Math.floor(Math.random() * 10);
  }
  return parseInt(number);
}

const id = Date.now()

function PaymentSuccess(props) {

  const [showConfetti, setShowConfetti] = React.useState(false)
  const [subscriptionData, setSubscriptionData] = React.useState({})
  const [expiryDate, setExpiryDate] = React.useState(new Date())
  const [publicationLink, setPublicationLink] = React.useState("/")
  const { userData, subscriptionData: subData } = useAuth()
  const navigate = useNavigate()
  const location = useLocation();
  const data = location.state;

  React.useEffect(() => {
    /// Temp work around for route protection
    if (!userData) navigate("/")
  }, [userData])

  React.useEffect(() => {
    const subscription = localStorage.getItem('subscription')
    if(JSON.parse(subscription)){
        setSubscriptionData(JSON.parse(subscription))
    } else {
        navigate("/")
    }
}, [])

  React.useEffect(() => {
    localStorage.setItem('paymentsuccess', 'true')
    const _publicationLink = localStorage.getItem('publicationLink')
    if(_publicationLink){
      setPublicationLink(_publicationLink)
    }
    setTimeout(() => {
      setShowConfetti(true)
    }, 2000)
  }, [])  

  React.useEffect(() => {
    const duration = subscriptionData?.duration
    const multiplier = duration === "Annually" ? 365 : duration === "Monthly" ? 30 : 1
    const newDate = new Date()
    newDate.setDate(newDate.getDate() + multiplier)
    console.log("ExpiryDate:", newDate)
    setExpiryDate(newDate)
  }, [subscriptionData?.duration])

  const handleExplosionComplete = () => {
    setShowConfetti(false);
    setTimeout(() => {
      setShowConfetti(true);
    }, 1000); // Trigger explosion after 1 sec
  };

  const durations = {
    "Annually": "1 Year",
    "Monthly": "1 Month",
    "Daily": "1 Day"
  }

  console.log("publicationLink:", publicationLink)

  return (
    <div className="bg-white flex flex-col items-stretch mt-2">
      <div className="container mx-auto ">
        <AuthHeader2 />
      </div>
      <Alert
        message="Important"
        description="Sometimes you might have to first receive email confirmation from services@ura.go.ug before you can start using the services. Make sure to refresh your browser after receiving the confirmation email."
        type="info"
        showIcon
        className="absolute right-5 top-32 max-w-sm"
      />
      <div className="bg-white flex w-full flex-col items-center px-5 py-5 max-md:max-w-full">
        <div className="flex w-[756px] max-w-full flex-col -ml-1 mt-5 mb-28 max-md:mr-2.5 max-md:my-10">
          <div className="flex max-w-full flex-col items-center">
            <FaCircleCheck size={70} color="#2BCE24" className='text-center mb-5' />
            {showConfetti && <ConfettiExplosion force={0.8} duration={9000} onComplete={handleExplosionComplete} />}
            <div className="text-violet-950 text-center text-2xl font-medium max-md:ml-2.5">
              {`${subscriptionData?.price?.toLocaleString()} /=`}
            </div>
            <div className="text-neutral-800 text-center text-base mt-3.5 max-md:max-w-full">
              Subscription Successful
            </div>
            <div className="text-neutral-400 text-center text-sm ml-4 mt-1.5 max-md:ml-2.5">
              {new Date().toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}
            </div>
          </div>
          <div className="items-stretch border border-[color:var(--lighter-grey-strokes,#ECEDED)] self-stretch flex flex-col mt-7 rounded-xl border-solid max-md:max-w-full">
            <div className="justify-center bg-slate-100 flex flex-col px-4 py-2 max-md:max-w-full">
              <div className="items-center flex gap-4 max-md:max-w-full max-md:flex-wrap">
                <div className="text-neutral-400 text-base leading-6 grow shrink basis-auto my-auto max-md:max-w-full">
                  Recipient
                </div>
                <div className="text-neutral-400 text-right text-base leading-6">
                  <span className="text-neutral-800">
                    {userData?.user?.username}
                    <br />
                  </span>
                  {/* <span className="text-neutral-400">ID-{data?.prn}</span> */}
                  <span className="text-neutral-400">ID-{id}</span>
                </div>
              </div>
            </div>
            <div className="justify-center bg-white flex flex-col px-4 py-4 max-md:max-w-full">
              <div className="items-stretch flex gap-4 max-md:max-w-full max-md:flex-wrap">
                <div className="text-neutral-400 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                  Package
                </div>
                <div className="text-neutral-800 text-right text-base leading-6 whitespace-nowrap">
                  {subscriptionData?.package}
                </div>
              </div>
            </div>
            <div className="justify-center bg-slate-100 flex flex-col px-4 py-4 max-md:max-w-full">
              <div className="items-stretch flex gap-4 max-md:max-w-full max-md:flex-wrap">
                <div className="text-neutral-400 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                  Duration
                </div>
                <div className="text-neutral-800 text-right text-base leading-6 whitespace-nowrap">
                  {durations[subscriptionData?.duration]}
                </div>
              </div>
            </div>
            <div className="justify-center bg-slate-100 flex flex-col pb-2.5 px-4 max-md:max-w-full">
              <div className="items-stretch flex gap-4 max-md:max-w-full max-md:flex-wrap">
                <div className="text-neutral-400 text-base leading-6 grow shrink basis-auto max-md:max-w-full">
                  Start Date
                  <br />
                  End Date
                </div>
                <div className="text-red-600 text-right text-base leading-6">
                  <span className="text-green-500">
                    {new Date().toLocaleDateString().split("/").join("-")}                    
                    <br />
                  </span>
                  <span className="text-red-600">
                    {expiryDate.toLocaleDateString().split("/").join("-")}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Link to={publicationLink} className="text-white text-center text-sm leading-5 tracking-wide justify-center items-center bg-bgBlue self-stretch mt-16 px-5 py-5 rounded-xl max-md:max-w-full max-md:mt-10">
            {publicationLink === '/' ? "Continue" : "Read Online"}
          </Link>
          {/* <Link to="/dashboard/info" className="text-violet-950 text-center text-sm leading-5 tracking-wide whitespace-nowrap justify-center items-center border border-[color:var(--Blue,#272969)] self-stretch mt-4 px-5 py-5 rounded-xl border-solid max-md:max-w-full">
            Go to dashboard
          </Link> */}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PaymentSuccess;
