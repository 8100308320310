import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useAuth } from '../Auth';
import { API_URL } from '../envs';
import logo2 from '../ulrc_logo.png';
import { AuthHeader2 } from './UnAuthHeader';
import Footer from './Footer';
import { FcGoogle } from "react-icons/fc";
import { jwtDecode } from "jwt-decode";
import qs from 'qs';
import { WHITELISTED_EMAILS } from '../whitelist';

const initialUserData = {
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    confirmPassword: ""
}

export const getSubscription = async (subscriber, userEmail) => {

    const query = qs.stringify({
        filters: {
            userEmail: {
                '$eq': subscriber.email
            }
        }
    }, {
        encodeValuesOnly: true,
    });

    try {
        const response = await axios.get(`${API_URL}/subscriptions?${query}`);
        const subscriptions = response?.data?.data || [];
        const currentDate = new Date();

        const activeSubscriptions = subscriptions.filter(subscription =>
            new Date(subscription.attributes.subscriptionEnd) > currentDate
        );

        const mySubscription = activeSubscriptions.reduce((latest, current) => {
            if (!latest || new Date(latest.attributes.subscriptionStart) < new Date(current.attributes.subscriptionStart)) {
                return current;
            }
            return latest;
        }, null);
        
        if (mySubscription) {
            const newSubscription = {
                ...mySubscription.attributes,
                userEmail: userEmail,
                numberOfUsers: "1 User"
            }

            await axios.post(`${API_URL}/subscriptions`, {
                data: newSubscription
            });

            return newSubscription
        } else {
            return null;
        }

    } catch (error) {
        console.error("ERROR:", error)
        return null
    }
}

function Signup() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const authToken = urlParams.get('authToken');

    const [user, setUser] = useState(initialUserData)
    const [consent, setConsent] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isPasswordVisible, setPasswordVisible] = useState(false)
    const [subscriber, setSubscriber] = useState(null)
    const navigate = useNavigate()
    const { userData } = useAuth()
    const location = useLocation()
    // const { role } = location.state

    useEffect(() => {
        /// Temp work around for route protection
        if (userData) navigate("/")
    }, [userData])

    useEffect(() => {
        if (authToken) {
            const decodedToken = jwtDecode(authToken);
            setSubscriber(decodedToken)
        }

    }, [authToken])

    const handleChange = (input) => {
        setUser({
            ...user,
            [input.key]: input.value
        })
    }

    const validation = () => {
        for (const property in user) {
            if (user[property].length === 0) {
                toast.error(`${property} field cannot be empty`, {
                    position: toast.POSITION.BOTTOM_CENTER
                })
                return false;
            }
        }

        if (user.password !== user.confirmPassword) {
            toast.error("Your passwords don't match", {
                position: toast.POSITION.BOTTOM_CENTER
            })
            return false;
        }

        return true
    }

    const register = async () => {
        // const whiteList = WHITELISTED_EMAILS
        // const currentDate = new Date()
        // const launchDate = new Date('2024-06-27T11:00:00')

        // if (currentDate < launchDate && !whiteList.includes(user.email)) {
        //     toast.error("Registration is disabled please wait for official launch on 27th June, 2024 😊", {
        //         position: toast.POSITION.BOTTOM_CENTER
        //     })
        //     return
        // }

        const registerData = { ...user, username: `${user.firstname} ${user.lastname}` }
        delete registerData['firstname']
        delete registerData['lastname']
        delete registerData['confirmPassword']
        const url = `${API_URL}/auth/local/register`

        try {
            setLoading(true)
            const resp = await axios.post(url, registerData)
            if (subscriber) {
                if (resp) {
                    const res2 = await axios.put(`${API_URL}/users/${resp.data.user.id}`, { subscribedTo: `${subscriber?.id}` });
                    await getSubscription(subscriber, user.email)

                    if (res2) {
                        navigate("/signup-success");
                    }
                }
            } else {
                navigate("/signup-success")
            }
        } catch (error) {
            const errorMsg = error?.response?.data?.error?.message || error.message
            const code = error?.response?.data?.error?.status || error.status
            // if(code === 400){
            // toast.error("Registration is disabled, please wait for official launch on 27th June, 2024 😊", {
            //     position: toast.POSITION.BOTTOM_CENTER
            // })
            // } else {
            toast.error(errorMsg, {
                position: toast.POSITION.BOTTOM_CENTER
            })
            // }
        } finally {
            setLoading(false)
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validation()) return;
        if (!consent) {
            toast.error(
                "Please agree to the terms and conditions",
                { position: toast.POSITION.BOTTOM_CENTER }
            )
            return;
        }
        await register()
    }

    const handleGoogleAuth = async (e) => {
        e.preventDefault()

        if (!consent) {
            toast.error(
                "Please agree to the terms and conditions",
                { position: toast.POSITION.BOTTOM_CENTER }
            )
            return;
        }

        window.location.href = `${API_URL}/connect/google`
    }

    return (
        <div className='h-full bg-bgGrey flex flex-col justify-between'>
            {/* <div className='bg-white py-5 pl-20'>
                <Link to={'/'}><img src={logo2} alt="logo" className='w-32' /></Link>
            </div> */}
            <div>
                <div className="container mx-auto">
                    <AuthHeader2 />
                </div>
                <div className='flex justify-center items-center flex-col pt-10 pb-20'>
                    <div className='text-5xl font-serif'>Create an Account</div>
                    <form className='mt-10' onSubmit={handleSubmit}>
                        {/* <div className='mb-4'>
                            <label className='block ml-1 mb-1'>Name</label>
                            <input
                                value={user.username}
                                onChange={(e) => handleChange({ key: 'username', value: e.target.value })}
                                className='h-10 pl-2 w-96 rounded-lg outline-none'
                                placeholder='ULRC User' />
                        </div> */}
                        <div className="grid grid-cols-2 gap-4 w-96">
                            <div className='mb-4'>
                                <label className='block ml-1 mb-1'>First Name</label>
                                <input
                                    value={user.firstname}
                                    onChange={(e) => handleChange({ key: 'firstname', value: e.target.value })}
                                    className='h-10 pl-2 rounded-lg outline-none'
                                    placeholder='Enter your First Name' />
                            </div>
                            <div className='mb-4'>
                                <label className='block ml-1 mb-1'>Last Name</label>
                                <input
                                    value={user.lastname}
                                    onChange={(e) => handleChange({ key: 'lastname', value: e.target.value })}
                                    className='h-10 pl-2 rounded-lg outline-none'
                                    placeholder='Enter your Last Name' />
                            </div>
                        </div>
                        <div className='mb-4'>
                            <label className='block ml-1 mb-1'>Email</label>
                            <input
                                value={user.email}
                                onChange={(e) => handleChange({ key: 'email', value: e.target.value })}
                                type='email' className='h-10 pl-2 w-96 rounded-lg outline-none'
                                placeholder='example@mail.com' />
                        </div>
                        <div className='mb-4'>
                            <label className='block ml-1 mb-1'>Password <span style={{ fontSize: 12 }}>(Must have more than 6 characters)</span></label>
                            <div className='w-96 flex items-center'>
                                <input
                                    value={user.password}
                                    onChange={(e) => handleChange({ key: 'password', value: e.target.value })}
                                    type={isPasswordVisible ? 'text' : 'password'}
                                    className='h-10 pl-2 w-80 rounded-lg outline-none'
                                    placeholder='xxxxxxxx' />
                                <div
                                    className='flex items-center justify-center w-16 h-10 rounded-r-lg cursor-pointer bg-white'
                                    onClick={() => setPasswordVisible(!isPasswordVisible)}
                                >
                                    {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                                </div>
                            </div>
                        </div>
                        <div className='mb-2'>
                            <label className='block ml-1 mb-1'>Confirm Password</label>
                            <input
                                value={user.confirmPassword}
                                onChange={(e) => handleChange({ key: 'confirmPassword', value: e.target.value })}
                                type='password' className='h-10 pl-2 w-96 rounded-lg outline-none'
                                placeholder='xxxxxxxx' />
                        </div>

                        {/* Add Disclaimer */}
                        <div className='flex items-center mb-2 mt-4'>
                            <input
                                type="checkbox"
                                className='mr-2'
                                checked={consent}
                                onChange={() => setConsent(!consent)}
                            />
                            <span className='text-sm'>I agree to the <a href='/terms-and-conditions' target='_blank' className='text-brightRed'>Terms and Conditions</a></span>
                        </div>

                        <button
                            type='submit'
                            disabled={loading}
                            className={`h-10 w-96 rounded-lg bg-brightRed text-white`}>{loading ? "Loading" : "Create Account"}</button>
                        {/* <div className="relative my-4">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-[80%] border-t border-gray-300 ml-[10%]" />
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-gray-100 text-gray-500">Or continue with</span>
                            </div>
                        </div>
                        <button
                            className="h-10 w-96 rounded-lg bg-white flex items-center justify-center gap-2 border border-blue-600"
                            onClick={handleGoogleAuth}
                        >
                            <FcGoogle size={20} />
                            <span className="text-blue-800">Signin with Google</span>
                        </button> */}
                        <div className='text-center mt-4'>
                            Already have an acccount? <a href='/signin' className='text-brightRed cursor-pointer'>Log in </a>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Signup